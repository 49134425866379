import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: () =>
                import ('./views/Login.vue'),
        },
        {
            // ======================
            // Full Layout
            // ======================
            path: '',
            component: () =>
                import ('./layout/full/MainContainer.vue'),
            // ======================
            // Theme routes / pages
            // ======================

            children: [{
                    path: '/participantes/:id?',
                    name: 'Participantes',
                    component: () =>
                        import ('./views/Participantes.vue')
                },
                {
                    path: '/trabajos/:id?',
                    name: 'Trabajos',
                    component: () =>
                        import ('./views/Trabajos.vue')
                },
                {
                    path: '/Extras/:id?',
                    name: 'Extras',
                    component: () =>
                        import ('./views/Extras.vue')
                },
                {
                    path: '/noticias/:id?',
                    name: 'Noticias',
                    component: () =>
                        import ('./views/Noticias.vue')
                },
                {
                    path: '/categorias/:id?',
                    name: 'Categorias',
                    component: () =>
                        import ('./views/Categorias.vue')
                },
                {
                    path: '/categorias_noticias/:id?',
                    name: 'Categorias Noticias',
                    component: () =>
                        import ('./views/CategoriasNoticias.vue')
                },
                {
                    path: '/usuarios/:id?',
                    name: 'Usuarios',
                    component: () =>
                        import ('./views/Usuarios.vue')
                },
                {
                    path: '/adminusers/:id?',
                    name: 'Usuarios Administradores',
                    component: () =>
                        import ('./views/Adminusers.vue')
                },

            ]
        },
    ]
})