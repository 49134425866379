<template>
  <div id="app">
    <router-view></router-view>
    <vs-popup class="holamundo" title="Error" :active.sync="viewError">
      <p style="color:red" v-for="(error, index) in errors" :key="index">{{error}}</p>
    </vs-popup>
    <vs-popup class="holamundo" title="Mensaje" :active.sync="viewAcierto">
      <p style="color:green" v-for="(acierto, index) in aciertos" :key="index">{{acierto}}</p>
    </vs-popup>
  </div>
</template>

<script>
import axios from 'axios'
import sidebarLinks from "@/layout/full/sidebar/sidebarlinks.js";

export default {
  name: 'app',
  components: {
  },
  data: function(){
    return{
      errors: [],
      aciertos:[],
      viewError: false,
      viewAcierto: false,
      user: null
    }
  },
  watch: {
    errors: function(val){
      if(this.errors.length > 0){
        this.viewError = true;
      }
    },aciertos: function(val){
      if(this.aciertos.length > 0){
        this.viewAcierto = true;
      }
    }
  },
  methods: {
    formatDate: function(format, fecha, actual){
      var date;
      if(fecha)
        date = new Date(fecha);
      else if(actual)
        date = new Date();

      format = format.replaceAll(/\bd\b/g, String(date.getDate()).padStart(2, '0'));
      format = format.replaceAll(/\bj\b/g, String(date.getDate()));
      format = format.replaceAll(/\bn\b/g, String(date.getMonth()+1));
      format = format.replaceAll(/\bm\b/g, String(date.getMonth()+1).padStart(2, '0'));
      format = format.replaceAll(/\bY\b/g, String(date.getFullYear()));
      //HORA
      format = format.replaceAll(/\bH\b/g, String(date.getHours()));
      format = format.replaceAll(/\bi\b/g, String(date.getMinutes()).padStart(2, '0'));
      format = format.replaceAll(/\bs\b/g, String(date.getSeconds()).padStart(2, '0'));

      return format;
      //return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();//YYYY-MM-dd
      //return String(date.getDate()).padStart(2, '0')+'/'+String(date.getMonth()+1).padStart(2, '0')+'/'+date.getFullYear();//dd-MM-YYYY
    },
    checkParams: function(required, el){
      this.errors = [];
      for(let i=0; i<required.length; i++){
          if(!el[required[i].name]){
              this.errors.push('Falta '+required[i].viewName);
          }
      }
      if(this.errors.length > 0)
        return true;
      else
        return false;
    },
    getImg: function(url){
      return fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          return blob;
        })
        .catch(() => {return null});
    },
    makeUrl: function(str){
      str = str.replaceAll(/á/g, 'a');
      str = str.replaceAll(/é/g, 'e');
      str = str.replaceAll(/í/g, 'i');
      str = str.replaceAll(/ó/g, 'o');
      str = str.replaceAll(/ú/g, 'u');
      str = str.replaceAll(/Á/g, 'A');
      str = str.replaceAll(/É/g, 'E');
      str = str.replaceAll(/Í/g, 'I');
      str = str.replaceAll(/Ó/g, 'O');
      str = str.replaceAll(/Ú/g, 'U');
      str = str.replaceAll(/ /g, '_');
      str = str.replaceAll(/ /g, '_');
      str = str.replaceAll(/[;,\/?:@&=+$¿~.!*'"()#]/g, '');

      return str
    },
    getUser: function(){
      axios
        .get(process.env.VUE_APP_URL+'adminfind', {
          params: {
            token: localStorage.getItem('adminuser')
          }
        })
        .then(response => {
          let rs = response.data;
          if(rs.state == 'OK'){
            this.user = rs.data;
            this.checkPrivilege(this.routeName);
          }
          else{
            localStorage.removeItem('adminuser');
            window.location.reload();
          }
        })
        .catch(error => {
          localStorage.removeItem('adminuser');
          window.location.reload();
        });
    },
    checkPrivilege: function(ruta){
      sidebarLinks.forEach(element => {
        if(ruta == element.name){
          if(element.permission < this.user.permission)
            this.$router.push('/');
        }
      });
    },
    logout: function(){
      axios
        .post(process.env.VUE_APP_URL+'adminlogout', {
            token: localStorage.getItem('adminuser')
        })
        .finally(() => {
          localStorage.removeItem('adminuser');
          window.location.reload();
        });
    },
    initParams: function(){
      if(localStorage.getItem('adminuser'))
        this.getUser();
      else
        this.$router.push('login');
    }
  },
  computed: {
    routeName(){
      return this.$route.name;
    }
  },
  created: function(){
    this.initParams();
  },
  beforeRouteUpdate (to, from, next) {
    this.initParams();
    next();
  },
}
</script>