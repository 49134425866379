	export default [{
	        url: "/participantes",
	        name: "Participantes",
	        icon: "person",
	        permission: 0
	    },
	    {
	        url: "/trabajos",
	        name: "Trabajos",
	        icon: "theaters",
	        permission: 0
	    },
	    {
	        url: "/extras",
	        name: "Extras",
	        icon: "theaters",
	        permission: 0
	    },
	    {
	        url: "/categorias",
	        name: "Secciones",
	        icon: "category",
	        permission: 0
	    },
	    {
	        url: "/noticias",
	        name: "Noticias",
	        icon: "rss_feed",
	        permission: 1
	    },
	    {
	        url: "/categorias_noticias",
	        name: "Categorias Noticias",
	        icon: "local_offer",
	        permission: 0
	    },
	    {
	        url: "/usuarios",
	        name: "Usuarios",
	        icon: "group",
	        permission: 0
	    },
	    {
	        url: "/adminusers",
	        name: "Usuarios Administradores",
	        icon: "group",
	        permission: 0
	    },
	]